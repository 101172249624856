const Colors = {
  primary: "#B85127", // orange
  secondary: "#5DB046", // green
  tertiary: "#2A2324", // black
  quaternary: "#8A8A8A", // dark gray
  backgroundPrimary: "#FCFAFA", // light gray
  backgroundSecondary: "#EAEAEA", // medium gray
  backgroundTertiary: "#000000", // empty
  backgroundQuaternary: "#000000", // empty
};

export default Colors;
